

























































import {Component, Vue, Watch} from 'vue-property-decorator';
import PersistentDialog from "@/components/global/dialog/PersistentDialog.vue";
import DataTableParameter from "@/models/DataTableOptions";
import MoodItem from "@/models/MoodItem";
import moment from "moment-mini";
import ViewDesign from "@/components/global/ViewDesign.vue";

@Component({
    components: {PersistentDialog, ViewDesign},
})
export default class GenerateEntries extends Vue {

    private loading: boolean = false;
    private totalCount: number = 0;
    private entriesToGenerate: number = 10;

    private requestCount=0;
    private get progressMessage() : string {
        return  "Eintrag " + this.requestCount + " / " + this.entriesToGenerate;
    }

    private entriesToGenerateChanged() {
        if(this.entriesToGenerate>300)
            this.entriesToGenerate=400;
    }
    private randomDate(start:Date, end:Date) : Date {
        return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
    }
    private delay = ms => new Promise(res => setTimeout(res, ms));
    private async validate() {

        try {
            this.loading = true;
            this.requestCount=0;
            const requests = new Array<Promise<any>>();
            //@ts-ignore
            if (this.$refs.addEntriesForm.validate()) {
                for (let i = 0; i < this.entriesToGenerate; i++) {
                    requests.push(this.addMoodItem(i));
                    await this.delay(500);
                }
                await Promise.all(requests);

                await this.$webApi.readMoodEntries(new DataTableParameter()).then(result => {
                    this.totalCount = result.data.count;
                    this.loading = false;
                });
            }
        }catch{

        } finally {
            this.loading = false;
        }
    }
    private async addMoodItem(number:number):Promise<any>{

        return this.$webApi.addMoodEntry(this.generateMoodItem(number)).then(()=>++this.requestCount).catch(()=>{
            Promise.reject();
        });
    }
    private generateMoodItem(number:number):MoodItem {

        const date = this.randomDate(new Date(2020,1,1),new Date());

        const dateString = moment(date).format("YYYY-MM-D");
        console.log(dateString);
        // 2021-12-18
        return new MoodItem(dateString,"",Math.floor(Math.random() * 5) + 1,"", null,null,"");
    }
    mounted() {
        this.$webApi.readMoodEntries(new DataTableParameter()).then(result => {
            this.totalCount = result.data.count;
        });

    }
}

